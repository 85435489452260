<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">
          <b-col sm="3">
            <b-row class="my-1">
              <label>日期查询:</label>
            </b-row>
            <b-row class="my-1">
              <v-app><v-dialog
                ref="dialog"
                v-model="datePickerModal.modal"
                :return-value.sync="dates"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="mdi-calendar"
                    readonly
                    placeholder="请选择日期"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  scrollable
                  full-width
                  range
                  :first-day-of-week="1"
                  locale="zh-cn"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalCancel()"
                  >
                    取消
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalOK()"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-dialog></v-app>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label>客户单位名称查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.customerName"
                placeholder="请输入客户单位名称"
                @change="order2page()"
              ></b-form-input>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label>商品名称查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.vegetableName"
                placeholder="请输入商品名称"
                @change="order2page()"
              ></b-form-input>
            </b-row>
          </b-col>
          
          <b-col sm="3">
            <b-row class="my-1">
              <label>供应商名称查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.supplierName"
                placeholder="请输入供应商名称"
                @change="order2page()"
              ></b-form-input>
            </b-row>
          </b-col>

          <b-col sm="12" style="margin-top: -20px;">
            <b-row >
              <b-col sm="3" class="my-1" style="padding-left: 0px;">
                  <v-app><v-dialog
                ref="dialog2"
                v-model="datePickerModal2.modal"
                :return-value.sync="dates2"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateText"
                    prepend-icon="mdi-calendar"
                    readonly
                    placeholder="请选择日期"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates2"
                  scrollable
                  full-width
                  :first-day-of-week="1"
                  locale="zh-cn"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalCancel2()"
                  >
                    取消
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalOK2()"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-dialog></v-app>
              </b-col>
              <b-col sm="3" class="my-1" style="padding-left: 0px;">
                <b-button
                  variant="primary"
                  @click="orderdelByDay"
                  >删除指定日期前的订单</b-button
                >
              </b-col>
            </b-row>
            
          </b-col>

          <b-col sm="12" class="mtop-50">
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="order2page()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button
               variant="success"
                @click.prevent="order2initAdd()"
                style="margin-right: 10px"
                >新增订单</b-button
              >
              <b-button 
                variant="success"
                v-if="multipleSelection.length == 0"
                >批量删除</b-button
              >
              <b-button
                v-else
                variant="primary"
                @click="delSelection"
                >批量删除</b-button
              >
              
              <b-button
                variant="primary"
                @click="bulkPort"
                style="margin-left: 10px"
                >批量导入</b-button
              >
              
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </div>

    <!--end::Header-->

    <!-- bootstrap -table   -start -->
    <div class="card-body pt-3 pb-0">
      <div class="table-responsive">
        <el-table
          ref="multipleTable"
          :data="orderList"
          tooltip-effect="dark"
          style="width: 100%"
          header-cell-style="background-color: #38485e;color:#fff;"
          @expand-change="handleExpandChange"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column label="#" type="index">
            <template slot-scope="scope">{{scope.$index + 1}}</template>
          </el-table-column>
          <el-table-column label="客户单位名称" min-width="130">
            <template slot-scope="scope">{{ scope.row.customerName }}</template>
          </el-table-column>
          <el-table-column label="商品名称" min-width="150">
            <template slot-scope="scope">{{ scope.row.vegetableCode }} {{ scope.row.vegetableName }}</template>
          </el-table-column>
          <el-table-column label="数量" min-width="50">
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
          <el-table-column label="单位" min-width="50">
            <template slot-scope="scope">{{ scope.row.unit }}</template>
          </el-table-column>
          <el-table-column label="供应商" min-width="100">
            <template slot-scope="scope">{{ scope.row.supplierName }}</template>
          </el-table-column>
          <el-table-column label="日期" min-width="100">
            <template slot-scope="scope">
                  {{ scope.row.createdDateDayOfWeek }}<br/>
                  {{ scope.row.createdDateMsg }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="80">
            <template slot-scope="scope">
                <!-- 编辑 -->
                <a
                  class="btn btn-icon btn-light btn-sm"
                  title="修改"
                  @click.prevent="order2initEdit(scope.row.id)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      title="修改"
                      src="media/svg/icons/Communication/Write.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </a>
                <!-- 删除 -->
                <a
                  class="btn btn-icon btn-light btn-sm mx-3"
                  title="删除"
                  @click.prevent="order2del(scope.row.id)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      title="删除"
                      src="media/svg/icons/General/Trash.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </a>
            </template>
          </el-table-column>
          <!-- 展开详情 -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <div>
                   <b>单价：</b>{{ scope.row.price }}<br/>
                  <b>创建者：</b>{{ scope.row.createdAccountName }}<br/>
                  <b>创建日期：</b>{{ scope.row.createdDate }}<br/>
                  <b>修改者：</b>{{ scope.row.modifiedAccountName }}<br/>
                  <b>修改日期：</b>{{ scope.row.modifiedDate }}<br/>
                  <b>描述：</b>{{ scope.row.description }}<br/>
                </div>
              </el-form>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 翻页按钮 -->
      <div class="mt-3">
        <b-pagination
          limit="7"
          first-number
          last-number
          size="lg"
          align="right"
          v-model="search.pageNum"
          :per-page="search.pageSize"
          :total-rows="rows"
        ></b-pagination>
      </div>
    </div>
    <!-- bootstrap -table   -end -->

    <!--begin::Body-->
    <div class="card-body pt-3 pb-0" style="display:none;">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 100px">客户单位名称</th>
              <th class="pl-0" style="min-width: 150px">商品名称</th>
              <th class="pl-0" style="min-width: 50px">数量</th>
              <th class="pl-0" style="min-width: 50px">单位</th>
              <th class="pl-0" style="min-width: 100px">供应商</th>
              <th class="pl-0" style="min-width: 100px">日期</th>
              <th class="pl-0" style="min-width: 50px">操作</th>
              <th class="pl-0" style="min-width: 50px"><b-icon icon="square"></b-icon></th>
              <th class="pl-0" style="min-width: 50px" data-checkbox="true"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in orderList">
              <tr v-bind:key="i">
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 客户单位名称   -->
                <td class="pl-0">
                  {{ item.customerName }}
                </td>

                <!-- 商品名称   -->
                <td class="pl-0">
                  {{ item.vegetableCode }} {{ item.vegetableName }}
                </td>

                <!-- 数量  -->
                <td class="pl-0">
                  {{ item.num }}
                </td>

                <!-- 单位  -->
                <td class="pl-0">
                  {{ item.unit }}
                </td>

                <!-- 供应商  -->
                <td class="pl-0">
                  {{ item.supplierName }}
                </td>

                <!-- 日期  -->
                <td class="pl-0">
                  {{ item.createdDateDayOfWeek }}<br/>
                  {{ item.createdDateMsg }}
                </td>

                <!-- 操作 -->
                <td class="pl-0" >
                  <!-- 编辑 -->
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    title="修改"
                    @click.prevent="order2initEdit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="修改"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 删除 -->
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="删除"
                    @click.prevent="order2del(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="删除"
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 详情 -->
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    title="详情"
                    @click.prevent="showDetails(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        v-show="!item.show"
                        title="详情"
                        src="media/svg/icons/Navigation/Angle-double-down.svg"
                      ></inline-svg>
                      <inline-svg
                        v-show="item.show"
                        title="详情"
                        src="media/svg/icons/Navigation/Angle-double-up.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
                <!-- 批量操作选项 -->
                <td class="pl-0">
                  <!-- <b-icon icon="square"></b-icon> -->
                  <b-icon icon="check-square"></b-icon>
                </td>
              </tr>
              <tr v-bind:key="-item.id" v-show="item.show" class="tr-detail">
                <td class="pl-0" colspan="8">
                  <b>单价：</b>{{ item.price }}<br/>
                  <b>创建者：</b>{{ item.createdAccountName }}<br/>
                  <b>创建日期：</b>{{ item.createdDate }}<br/>
                  <b>修改者：</b>{{ item.modifiedAccountName }}<br/>
                  <b>修改日期：</b>{{ item.modifiedDate }}<br/>
                  <b>描述：</b>{{ item.description }}<br/>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- 翻页按钮 -->
        <div class="mt-3">
          <b-pagination
            limit="7"
            first-number
            last-number
            size="lg"
            align="right"
            v-model="search.pageNum"
            :per-page="search.pageSize"
            :total-rows="rows"
          ></b-pagination>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

    <LcxOrderAdd ref="orderAdd" @order2page="order2page"></LcxOrderAdd>
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
.mtop-50 {
  margin-top: -50px !important;
}
.tr-detail {
  td {
    background-color: #f1f1f1;
    padding-left: 20px !important;
  }
}
</style>

<script>
let that;
import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import Order from "@/assets/js/pages/vegetable/order.js";
import LcxOrderAdd from "@/view/pages/vegetable/include/order-add.vue";

export default {
  name: "order-list",

  data() {
    return {
      search: {
        pageNum: 1,
        startDate: "",
        endDate: "",
        // pageSize: 5,
        vegetableName: "",
        customerName: "",
        supplierName: ""
      },
      dates: [
        '2021-08-26',
        '2021-08-26'
      ],
      dates2: "",
      datePickerModal: {
        modal: false,
        use: false
      },
      datePickerModal2: {
        modal: false,
        use: false
      },
      rows: 100,
      orderList: [],
      orderDialogData: {},
      idsDel: [],
      multipleSelection: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    dateRangeText () {
      return this.dates.join(' ~ ')
      // if (this.datePickerModal.use) {
      //   return this.dates.join(' ~ ')
      // }
      // return ''
    },
    dateText () {
      return this.dates2;
    }
  },
  components: {
    LcxOrderAdd
  },
  watch: {
    "search.pageNum": function () {
      // console.log('pageNum change to %d from %d', newV, oldV);
      this.order2page();
    }
  },
  mounted() {
    that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "账户管理", route: "" },
      { title: "账户列表" }
    ]);
    //this.order2page();
    setTimeout(this.getDataList, 1000);

    //==================================
    that.dayDataList();
  },
  methods: {
    datePickerModalCancel: function () {
     that.datePickerModal.use = false;
     that.datePickerModal.modal = false;
     that.order2page();
    },
    datePickerModalOK: function () {
     that.datePickerModal.use = true;
     that.$refs.dialog.save(that.dates);
     that.order2page();
    },
    // 删除某定日期前的订单
    datePickerModalCancel2: function () {
     that.datePickerModal2.use = false;
     that.datePickerModal2.modal = false;
    },
    datePickerModalOK2: function () {
     that.datePickerModal2.use = true;
     that.$refs.dialog2.save(that.dates2);
    //  that.order2page();
    },
    //===================================
     yyyyMMdd: function(y, m, d) {
      (m < 10) && (m = "0" + m);
      (d < 10) && (d = "0" + d);
      return y + "-" + m + "-" + d;
    },
    getMonday: function(dd) {
      var week = dd.getDay(); //获取时间的星期数
      var minus = week ? week - 1 : 6;
      dd.setDate(dd.getDate() - minus); //获取minus天前的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = dd.getDate();
      return that.yyyyMMdd(y, m, d);
    },
    getMonthFirstDay: function(dd) {
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = 1;
      return that.yyyyMMdd(y, m, d);
    },
    getToday: function() {
      let dd = new Date();
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = dd.getDate();
      return that.yyyyMMdd(y, m, d);
    },
    dayDataList: function () {
      that.search.startDate = that.getToday();
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
      //==================
      that.initOrder();
    },
    weekDataList: function () {
      that.search.startDate = that.getMonday(new Date());
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
    },
    monthDataList: function () {
      that.search.startDate = that.getMonthFirstDay(new Date());
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
    },
    //==================================
    getDataList: function () {
      Order.getDataList().then(({ data }) => {
        that.orderDialogData = data;
      });
    },
    showDetails: function(item) {
      if (item.createdAccountName) {
        item.show = !item.show
      } else {
        Order.getAccountName(item).then(({ data }) => {
          item.createdAccountName = data.createdAccountName
          item.modifiedAccountName = data.modifiedAccountName
          item.show = !item.show
        });
      }
    },
    order2page: function () {
      //================
      // console.log(that.search)
      that.search.startDate = that.dates[0];
      that.search.endDate = that.dates[1];
      //if (that.datePickerModal.use) {
      //  that.search.startDate = that.dates[0];
      //  that.search.endDate = that.dates[1];
      //} else {
      //  that.search.startDate = null;
      //  that.search.endDate = null;
      //}
      Order.list(this.search).then(({ data }) => {
        data.list.map(e => {
          e.createdAccountName = '';
          e.modifiedAccountName = '';
        })
        that.orderList = data.list;;

        that.search.pageSize = data.pageSize;
        that.search.pageNum = data.pageNum;
        that.rows = data.total;
      });
    },
    initOrder: function () {
      // console.log(that.search)
      Order.list(this.search).then(({ data }) => {
        data.list.map(e => {
          e.createdAccountName = '';
          e.modifiedAccountName = '';
        })
        that.orderList = data.list;;

        that.search.pageSize = data.pageSize;
        that.search.pageNum = data.pageNum;
        that.rows = data.total;
      });
    },
    order2initAdd: function () {
      this.$refs.orderAdd.order2initAdd(that.orderDialogData);
    },
    order2initEdit: function (id) {
      this.$refs.orderAdd.order2initEdit(id, that.orderDialogData);
    },
    order2del: function (id) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Order.del(id).then(({ msg }) => {
            // 重新加载页面列表
            this.order2page();
            // 消息弹框
            window.Toast("success", msg);
          });
        }
      });
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection)
    },
    handleExpandChange(item) {
      Order.getAccountName(item).then(({ data }) => {
        item.createdAccountName = data.createdAccountName
        item.modifiedAccountName = data.modifiedAccountName
      });
    },
    delSelection() {
        var ids = [], multipleSelection = this.multipleSelection
        multipleSelection.forEach(e => {
          ids.push(e.id)
        });

        Swal.fire({
          title:
            '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
          showCancelButton: true,
          confirmButtonText: `确定`,
          cancelButtonText: `取消`
        }).then(result => {
          if (result.isConfirmed) {
            Order.dels(ids).then(({ msg }) => {
              // 重新加载页面列表
              this.order2page();
              // 消息弹框
              window.Toast("success", msg);
            });
          }
        });
    },
    // delSelection1() {
    //   var ids = [], multipleSelection = this.multipleSelection

    //   Swal.fire({
    //     title:
    //       '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
    //     showCancelButton: true,
    //     confirmButtonText: `确定`,
    //     cancelButtonText: `取消`
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       multipleSelection.forEach(e => {
    //         Order.del(e.id).then(({ msg }) => {
    //           // 重新加载页面列表
    //           this.order2page();
    //           // 消息弹框
    //           window.Toast("success", msg);
    //         });
    //       });
    //     }
    //   });

    //   multipleSelection.forEach(e => {
    //     ids.push(e.id)
    //   });
    //   // console.log(ids)
    // }
    bulkPort(){//跳转批量导入
      this.$router.push('/order-import')
    },
    // 删除指定日期前的订单
    orderdelByDay: function () {
      if(!this.dates2) {
        window.Toast("error", "请选择删除的日期");
        return false;
      }
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除' + this.dateText +'之前的所有订单吗？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Order.delsByDay({createdDate: this.dates2}).then(({ msg }) => {
            // 重新加载页面列表
            this.order2page();
            // 消息弹框
            window.Toast("success", msg);
          });
        }
      });
    },
  }
};
</script>
