import ApiService from "@/core/services/api.service";

var Order = {
    list: function(searchData) {
        return ApiService.post("/order/list", searchData);
    },
    save: function(order) {
        return ApiService.post("/order/save", order);
    },
    del: function(id) {
        return ApiService.get("/order/del?id=" + id);
    },
    dels: function(ids) {
        return ApiService.post("/order/dels", ids);
    },
    delsByDay: function(data) {
        return ApiService.post("/order/delsByDay", data);
    },
    findById: function(id) {
        return ApiService.get("/order/findById?id=" + id);
    },
    getDataList: function() {
        return ApiService.get("/order/getDataList");
    },
    getSelectDataList: function(params) {
        return ApiService.post("/order/getSelectDataList", params);
    },
    fileUpload: function(params) {
        return ApiService.post("/order/fileUpload", params);
    },
    getAccountName(params) {
        return ApiService.post("/order/getAccountName", {
            createdAccountId: params.createdAccountId,
            modifiedAccountId: params.modifiedAccountId
        });
    }
}

export default Order;