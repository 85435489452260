<template>
    <v-app>
        <v-row justify="center">
        <v-dialog
            v-model="orderDialog.dialog"
            persistent
            scrollable
            max-width="1200px"
        >
            <v-form
            ref="form"
            lazy-validation
            >
            <v-card>
                <!-- 表单头部 -->
                <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ orderDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="orderDialog.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>

                <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                  <v-col cols="7" style="height: 400px; overflow: auto;" >
                    <v-row>
                    <!-- 客户单位名称   -->
                    <v-col cols="6" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >客户单位 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="orderDialog.customerName"
                            autocomplete="off"
                            outlined
                            :rules="[myRules.customerId]"
                            @click="changeBySelect('customer')"
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 商品类别 商品编号 商品名称   -->
                    <v-col cols="6" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >商品名称 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        >（所属类别：{{ orderDialog.vegetableTypeName }}）</span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="orderDialog.vegetableName"
                            autocomplete="off"
                            outlined
                            :rules="[myRules.vegetableId]"
                            @click="changeBySelect('vegetable')"
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    </v-row>
                    <v-row>

                    <!-- 数量 单位  -->
                    <v-col cols="6">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >数量*</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currOrder.num"
                            autocomplete="off"
                            :rules="[myRules.required, myRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 计量单位  -->
                    <v-col cols="6">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >计量单位 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        >（预定计量单位：{{ orderDialog.unit }}）</span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currOrder.unit"
                            autocomplete="off"
                            :rules="[myRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>
                    
                    </v-row>
                    <v-row>

                    <!--  采购价格 -->
                    <v-col cols="6">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >采购价格 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        >（预定采购价：{{ orderDialog.priceBuy }}）</span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currOrder.price"
                            autocomplete="off"
                            :rules="[myRules.required, myRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 供应商  -->
                    <v-col cols="6" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >供应商 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="orderDialog.supplierName"
                            autocomplete="off"
                            :rules="[myRules.supplierId]"
                            outlined
                            @click="changeBySelect('supplier')"
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>
                    
                    </v-row>
                    <v-row>

                    <!-- 日期 -->
                    <v-col cols="6">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >日期 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-dialog
                          ref="dialog"
                          v-model="datePickerModal.modal"
                          :return-value.sync="currOrder.createdDate"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currOrder.createdDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currOrder.createdDate"
                            scrollable
                            full-width
                            :first-day-of-week="1"
                            locale="zh-cn"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="datePickerModal.modal = false"
                            >
                              取消
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(currOrder.createdDate)"
                            >
                              确认
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                        </div>
                    </v-col>

                    <!-- 备注 -->
                    <v-col cols="6" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >备注</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                          <v-textarea
                            v-model="currOrder.description"
                            auto-grow
                            outlined
                            rows="1"
                            dense
                          ></v-textarea>
                        </div>
                    </v-col>

                    </v-row>
                  
                  </v-col>
                  <v-col cols="5" style="height: 400px; overflow: auto;" > 
                    <!-- 搜索 -->
                    <v-col cols="12" >
                        <div  class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >搜索：{{ orderDialog.typeTitle }}</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        >（共：{{ orderDialog.totalItem }}条，搜索到：{{ orderDialog.showItem }}条）</span>
                        </div>
                        <div >
                        <v-text-field 
                            type="input"
                            v-model="orderDialog.name"
                            append-icon="mdi-search"
                            @change="searchByName()"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                        <div style="margin-top: -20px; height: 300px; overflow: auto;" >
                        
                            <v-radio-group column v-show="orderDialog.type == 'customer'" >
                            <v-radio
                                v-for="item in orderDialog.customerList"
                                :key="item.id"
                                v-show="item.show"
                                color="primary"
                                :label="item.name"
                                :value="item.id"
                                hide-details
                                @click="changeTypeValue(item)"
                                dense
                            ></v-radio>
                            </v-radio-group>

                            <v-radio-group column v-show="orderDialog.type == 'vegetable'" >
                            <v-radio
                                v-for="item in orderDialog.vegetableList"
                                :key="item.id"
                                v-show="item.show"
                                color="primary"
                                :label="item.name"
                                :value="item.id"
                                hide-details
                                @click="changeTypeValue(item)"
                                dense
                            ></v-radio>
                            </v-radio-group>

                            <v-radio-group column v-show="orderDialog.type == 'supplier'" >
                            <v-radio
                                v-for="item in orderDialog.supplierList"
                                :key="item.id"
                                v-show="item.show"
                                color="primary"
                                :label="item.name"
                                :value="item.id"
                                hide-details
                                @click="changeTypeValue(item)"
                                dense
                            ></v-radio>
                            </v-radio-group>
                            
                        </div>
                    </v-col>
                  </v-col>
                  </v-row>
                </v-container>
                
                </v-card-text>

                <!-- start:按钮区 -->
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="primary" @click.prevent="order2save()"
                  v-if="orderDialog.isAdd"> 保存 </v-btn>

                <v-btn class="ma-2" color="success" @click.prevent="order2saveAndClose()"> 保存并关闭 </v-btn>

                <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="closeDialog()"
                >
                    关闭
                </v-btn>
                </v-card-actions>
                <!-- end:按钮区 -->
            </v-card>
            </v-form>
        </v-dialog>
        </v-row>
    </v-app>
</template>

<style lang="scss">
</style>

<script>
let that;
import Order from "@/assets/js/pages/vegetable/order.js";

export default {
  name: "LcxOrderAdd",
  data() {
    return {
      myRules: {
        customerId: () => {
          const pattern = /^\d+$/
          return pattern.test(that.currOrder.customerId) || '必选项.'
        },
        supplierId: () => {
          const pattern = /^\d+$/
          return pattern.test(that.currOrder.supplierId) || '必选项.'
        },
        vegetableId: () => {
          const pattern = /^\d+$/
          return pattern.test(that.currOrder.vegetableId) || '必选项.'
        },
        number: v => { // 整数（正数 负数 0）
          const pattern = /^-?\d+$/
          return pattern.test(v) || '必须是整数.'
        },
        float: v => { // 整数（正数 负数 0）
          const pattern = /^-?\d+(\.\d+)?$/
          return pattern.test(v) || '必须是整数.'
        },
        positiveNumber: v => { // 正整数
          const pattern = /^\d+$/
          return pattern.test(v) || '必须是正整数.'
        },
        selected: v => !!v || '必选',
        required: v => !!v || '不能为空',
        len_6_20: v => (v.length >= 6 && v.length <= 20) || "长度至少6位，最多20位"
      },
      currOrder: {
        id: null,
        num: null,
        price: null,
        unit: null,
        customerId: null, // customerId vegetableId  num  supplierId
        supplierId: null,
        vegetableId: null,
        description: null,
        createdDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      orderDialog: {
        title: "新增订单", 
        addCount: 0,
        dialog: false,
        isAdd: false,
        customerList: [],
        vegetableList: [],
        supplierList: [],
        typeTitle: "客户单位",
        type: "customer",
        typeList: [],
        customerName: "",
        vegetableName: "",
        supplierName: "",
        unit: "",
        name: "",
        showItem: 0,
        totalItem: 0
      },
      datePickerModal: {
        modal: false
      }
    };
  },
  mounted() {
      that = this;
      // that.setHeight();
      // alert("add");
  },
  methods: {
    // setHeight: function() {
    //   that.$nextTick(() => {
    //     let h = that.$refs.cardText.offsetHeight;
    //     // let cardText = that.$refs.cardText;
    //     // console.log(cardText);
    //     let leftCol = that.$refs.leftCol;
    //     let rightCol = that.$refs.rightCol;
    //     leftCol.style.height = h;
    //     rightCol.style.height = h;
    //   });
    // },
    resetOrder: function () {
      that.currOrder = {
        id: null,
        num: null,
        price: null,
        unit: null,
        customerId: null, // customerId vegetableId  num  supplierId
        supplierId: null,
        vegetableId: null,
        description: null,
        createdDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      };
      that.orderDialog = {
        title: "新增订单", 
        addCount: 0,
        dialog: false,
        isAdd: false,
        customerList: [],
        vegetableList: [],
        supplierList: [],
        typeTitle: "客户单位",
        type: "customer",
        typeList: [],
        customerName: "",
        vegetableName: "",
        supplierName: "",
        unit: "",
        name: "",
        showItem: 0,
        totalItem: 0
      };
      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },
    resetOrderAfterSave: function () {
      that.currOrder.id = null;
      that.currOrder.num = null;
      that.currOrder.price = null;
      that.currOrder.unit = null;
      that.currOrder.supplierId = null;
      that.currOrder.vegetableId = null;
      that.currOrder.description = null;

      that.orderDialog.vegetableName = "";
      that.orderDialog.supplierName = "";
      that.orderDialog.unit = "";

      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
      
      that.orderDialog.addCount++;
      that.orderDialog.title = "已新增订单（" + that.orderDialog.addCount + "条）";
    },
    order2save: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Order.save(that.currOrder, that.orderDialog).then(({ msg }) => {
        // 消息弹框
        window.Toast("success", msg);
        that.resetOrderAfterSave();
      });
    },
    closeDialog: function() {
      that.orderDialog.dialog = false;
      if (that.orderDialog.addCount > 0) {
        that.$emit('order2page');
      }
    },
    order2saveAndClose: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Order.save(that.currOrder, that.orderDialog).then(({ msg }) => {
        // 关闭模式窗口
        that.orderDialog.dialog = false;
        // 重新加载页面列表
        that.$emit('order2page');
        // 消息弹框
        window.Toast("success", msg);
      });
    },
    changeBySelect: function(type) {
      if (type == "customer") {
        that.orderDialog.typeList = that.orderDialog.customerList;
        that.orderDialog.typeTitle = "客户单位";
      } else if (type == "vegetable") {
        that.orderDialog.typeList = that.orderDialog.vegetableList;
        that.orderDialog.typeTitle = "商品名称";
      } else if (type == "supplier") {
        that.orderDialog.typeList = that.orderDialog.supplierList;
        that.orderDialog.typeTitle = "供应商";
      }
      that.orderDialog.type = type;
      that.orderDialog.name = "";
      that.searchByName();
    },
    searchByName: function() {
      that.orderDialog.showItem = 0;
      that.orderDialog.totalItem = 0;

      if (that.orderDialog.name == "") {
        that.showAllTypeList();
      } else {
        that.changeTypeList();
      }
    },
    showAllTypeList: function() {
      if (that.orderDialog.type == "customer") {
        this.showAllcustomerList()
      } else if (that.orderDialog.type == "vegetable") {
        this.showAllvegetableList()
      } else if (that.orderDialog.type == "supplier") {
        this.showAllsupplierList()
      }
    },
    showAllcustomerList: function() {
      for (var item of that.orderDialog.customerList) {
        item.show = true;
        that.orderDialog.showItem += 1;
        that.orderDialog.totalItem += 1;
      }
    },
    showAllvegetableList: function() {
      for (var item of that.orderDialog.vegetableList) {
        item.show = true;
        that.orderDialog.showItem += 1;
        that.orderDialog.totalItem += 1;
      }
    },
    showAllsupplierList: function() {
      for (var item of that.orderDialog.supplierList) {
        item.show = true;
        that.orderDialog.showItem += 1;
        that.orderDialog.totalItem += 1;
      }
    },
    changeTypeList: function() {
      let thislist = []
      if (that.orderDialog.type == "customer") {
        thislist = that.orderDialog.customerList
      } else if (that.orderDialog.type == "vegetable") {
        thislist = that.orderDialog.vegetableList
      } else if (that.orderDialog.type == "supplier") {
        thislist = that.orderDialog.supplierList
      }
      for (var item of thislist) {
        if (item.name.indexOf(that.orderDialog.name) != -1) {
          item.show = true;
          that.orderDialog.showItem += 1;
        } else {
          item.show = false;
        }
        that.orderDialog.totalItem += 1;
      }
    },
    changeTypeValue: function(data) {
      if (that.orderDialog.type == "customer") {
        that.orderDialog.customerName = data.name;
        that.currOrder.customerId = data.id;
      } else if (that.orderDialog.type == "vegetable") {
        that.orderDialog.vegetableName = data.name;
        that.orderDialog.vegetableTypeName = data.vegetableTypeName;
        that.orderDialog.unit = data.unit;
        that.orderDialog.priceBuy = data.priceBuy;
        that.orderDialog.supplierName = data.supplierName;

        that.currOrder.vegetableId = data.id;
        that.currOrder.price = data.priceBuy;
        that.currOrder.unit = data.unit;
        that.currOrder.supplierId = data.supplierId;
      } else if (that.orderDialog.type == "supplier") {
        that.orderDialog.supplierName = data.name;
        that.currOrder.supplierId = data.id;
      }
      
    },
    order2initAdd: function (data) {
      that.resetOrder();

      that.orderDialog.customerList = data.customerList;
      that.orderDialog.vegetableList = data.vegetableList;
      that.orderDialog.supplierList = data.supplierList;
      that.orderDialog.typeList = data.customerList;

      // 模式窗口 - 新增初始化
      that.orderDialog.dialog = true;
      that.orderDialog.title = "新增订单";
      that.orderDialog.isAdd = true;

      that.orderDialog.name = "";
      that.searchByName();

      // that.setHeight();
    },
    order2initEdit: function (id, data) {
      that.resetOrder();

      that.orderDialog.customerList = data.customerList;
      that.orderDialog.vegetableList = data.vegetableList;
      that.orderDialog.supplierList = data.supplierList;
      that.orderDialog.typeList = data.customerList;

      Order.findById(id).then(({ data }) => {
        // 模式窗口 - 修改初始化
        that.currOrder = data;
        that.currOrder.createdDate = data.createdDateMsg
        that.orderDialog.dialog = true;
        that.orderDialog.title = "修改订单";
        that.orderDialog.isAdd = false;

        that.orderDialog.customerName = data.customerName;

        that.orderDialog.vegetableName = data.vegetableName;
        that.orderDialog.unit = data.unit;

        that.orderDialog.supplierName = data.supplierName;

        that.orderDialog.name = "";
        that.searchByName();

        // that.setHeight();
      });
    },
  }
};
</script>